import type { IQuery } from "@citrine/client";
import { QueryClient, refreshAccessToken } from "@citrine/client";
import { login, logout, queryClient } from "@citrine/client/utils";
import type {
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { useMutation, useQuery } from "@tanstack/react-query";
import type { AxiosError, AxiosRequestConfig } from "axios";

import { getUserRequestClient } from "../user-requests";

import type { INewUser } from ".";
import type { CognitoStatusResponse, IUser, Terms } from "./user";

interface UserQuery extends IQuery {
  as_admin?: boolean;
}

const ME_QUERY_KEY = ["me"];

class UserClient extends QueryClient<IUser, string, UserQuery> {
  constructor() {
    super({
      endpoint: "/api/v1/users",
      getField: "user",
      findField: "users",
      defaultQuery: {
        as_admin: undefined,
      },
    });
  }

  async create(model: INewUser): Promise<IUser> {
    const result = await super.create(model);
    // user creation will remove related user requests as a side-effect, so we must invalidate
    queryClient.invalidateQueries({
      queryKey: getUserRequestClient().getQueryCacheKey(),
    });
    return result;
  }

  async patch(args: [string, Partial<IUser>]): Promise<IUser> {
    const user = await super.patch(args);
    this.updateCacheIfMe(user);
    return user;
  }

  async update(data: IUser): Promise<IUser> {
    const user = await super.update(data);
    this.updateCacheIfMe(user);
    return user;
  }

  useCreate(options: UseMutationOptions<IUser, AxiosError, INewUser> = {}) {
    return super.useCreate(options) as UseMutationResult<
      IUser,
      AxiosError,
      INewUser
    >;
  }

  useFind(
    query?: UserQuery,
    options: Omit<UseQueryOptions<IUser[]>, "queryKey" | "queryFn"> = {}
  ) {
    return super.useFind(query, {
      gcTime: 300000,
      staleTime: 300000,
      ...options,
    });
  }

  public async getMe(): Promise<IUser | null> {
    // SWC seems to have a bug when run in dev mode, so we have to await here
    // and store as a local variable rather than passing to this.toModel inline
    const payload = await this.request<IUser>({
      url: `${this.endpoint}/me`,
    });
    return this.toModel(payload);
  }

  public login() {
    login();
  }

  public logout() {
    logout();
  }

  public useGetMe(
    options: Omit<UseQueryOptions<IUser>, "queryKey"> = {}
  ): UseQueryResult<IUser | null> {
    return useQuery({
      ...options,
      queryKey: ME_QUERY_KEY,
      queryFn: () => this.getMe(),
    });
  }

  public getCognitoStatus(userId: string): Promise<CognitoStatusResponse> {
    return this.request<CognitoStatusResponse>({
      url: `${this.endpoint}/${userId}/cognito-status`,
    }).catch(() => ({
      status: "UNKNOWN",
      enabled_mfa_methods: [],
      preferred_mfa_method: null,
    }));
  }

  public useGetCognitoStatus(userId: string) {
    return useQuery({
      queryKey: [...this.getModelCacheKey(userId), "cognito-status"],
      queryFn: () => this.getCognitoStatus(userId),
    });
  }

  public resendTemporaryPassword(userId: string) {
    return this.request<void>({
      method: "POST",
      url: `${this.endpoint}/${userId}/new-temporary-password`,
    });
  }

  public useResendTemporaryPassword(userId: string) {
    return useMutation({
      mutationFn: () => this.resendTemporaryPassword(userId),
    });
  }

  public getTermsAcceptance(userId: string) {
    return this.request<Terms>({
      method: "GET",
      url: `${this.endpoint}/${userId}/terms-acceptance`,
    });
  }

  public useGetTermsAcceptance(userId: string) {
    return useQuery({
      queryKey: [...this.getModelCacheKey(userId), "terms-acceptance"],
      queryFn: () => this.getTermsAcceptance(userId),

      refetchInterval: false,
    });
  }

  public async postTermsAcceptance(userId: string) {
    const result = await this.request<Terms>({
      method: "PUT",
      url: `${this.endpoint}/${userId}/terms-acceptance`,
    });
    // the terms boolean is stored in the access token, so we need to refresh it
    await refreshAccessToken(true);
    return result;
  }

  public usePostTermsAcceptance(userId: string) {
    return useMutation({
      mutationFn: () => this.postTermsAcceptance(userId),
      onSuccess: () => {
        // we don't really need to re-fetch this data
        // if the request is successful, we know they accepted the terms
        queryClient.setQueryData(
          [...this.getModelCacheKey(userId), "terms-acceptance"],
          { terms_agreed: true }
        );
      },
    });
  }

  // make sure delete_from_cognito flag gets passed in user deletion endpoint.
  protected deleteConfig(id: string): AxiosRequestConfig {
    return {
      ...super.deleteConfig(id),
      data: {
        delete_from_cognito: true,
      },
    };
  }

  protected updateConfig(model: IUser): AxiosRequestConfig {
    return {
      ...super.updateConfig(model),
      method: "PATCH",
    };
  }

  protected toModel(payload: any): IUser {
    const user = super.toModel(payload);
    user.is_citrine_user = user.email?.toLowerCase().endsWith("@citrine.io");
    return user;
  }

  private updateCacheIfMe(user: IUser) {
    const me = queryClient.getQueryData<IUser>(ME_QUERY_KEY);
    if (user.id === me?.id) {
      queryClient.setQueryData(ME_QUERY_KEY, user);
    }
  }
}

const userClient = new UserClient();
export const getUserClient = () => userClient;
