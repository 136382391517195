import { queryClient } from "@citrine/client/utils";
import type { FeatureFlag } from "@citrine/configuration";
import { FEATURE_FLAG_DEFAULTS } from "@citrine/configuration";
import { useQuery } from "@tanstack/react-query";

import { RUNTIME_CONFIG_KEY } from "./configuration";
import type { IConfiguration } from "./configuration";

const QUERY_KEY = [...RUNTIME_CONFIG_KEY, "customization"];
const LOCAL_STORAGE_KEY = QUERY_KEY.join("/");

type CustomConfig = {
  [K in keyof IConfiguration]: {
    value: IConfiguration[K];
    enabled: boolean;
  };
};

const CUSTOMIZATION_OVERRIDES = {
  defaultDSIntermediates: false, // the BE reads this flag for design space generation
  hasFeaturizedDataSourceAccess: false, // the BE reads this flag to gate API access
  maxMaterialsPaginationPageSize: true,
  predictorEvaluationResultDownsamplingLimit: true,
} as const satisfies Partial<Record<FeatureFlag, boolean>>;

export const isCustomizableFlag = (key: FeatureFlag) =>
  // enableFeatureCustomization is never customizable
  key !== "enableFeatureCustomization" &&
  ((CUSTOMIZATION_OVERRIDES as any)[key] ??
    typeof FEATURE_FLAG_DEFAULTS[key] === "boolean");

function getCustomizableConfig(customConfig: Partial<CustomConfig> | null) {
  return customConfig
    ? Object.fromEntries(
        Object.entries(customConfig).filter(([key]: [FeatureFlag, any]) =>
          isCustomizableFlag(key)
        )
      )
    : customConfig;
}

function getCustomConfig() {
  return getCustomizableConfig(
    JSON.parse(
      window.localStorage.getItem(LOCAL_STORAGE_KEY) ?? "null"
    ) as Partial<CustomConfig> | null
  );
}

export function setCustomConfig(customConfig: Partial<CustomConfig> | null) {
  if (customConfig == null) {
    window.localStorage.removeItem(LOCAL_STORAGE_KEY);
  } else {
    window.localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify(getCustomizableConfig(customConfig))
    );
  }
  queryClient.invalidateQueries({ queryKey: QUERY_KEY });
}

export function useCustomConfig() {
  return useQuery({ queryKey: QUERY_KEY, queryFn: () => getCustomConfig() })
    .data;
}
