import type { IConfiguration } from ".";
import { ConfigurationClient } from ".";

// overload so typing of return value is correct with empty call
// and with keyed call
export function useConfiguration(): IConfiguration;
export function useConfiguration<K extends keyof IConfiguration>(
  key: K
): IConfiguration[K];
export function useConfiguration<K extends keyof IConfiguration>(
  key: K,
  defaultValue: NonNullable<IConfiguration[K]>
): NonNullable<IConfiguration[K]>;
export function useConfiguration<K extends keyof IConfiguration>(
  key?: K,
  defaultValue?: IConfiguration[K]
) {
  const configurationQuery =
    ConfigurationClient.getInstance().useGetRuntimeConfig();

  return key === undefined
    ? configurationQuery.data
    : (configurationQuery.data![key] ?? defaultValue);
}
