import { isIntegrationTest, isProduction } from "@citrine/configuration";
import { ConfigurationClient } from "@citrine/resources/configuration";
import type { IUser } from "@citrine/resources/user";
import { Suspense, lazy, useCallback, useState } from "react";

import { SplashScreen } from "src/components/splash-screen";

const App = lazy(() => import(/* webpackChunkName: "router" */ "../app"));

const showSplashScreen = isProduction || isIntegrationTest;

export const AppLoader = (props: { me: IUser }) => {
  const runtimeConfig = ConfigurationClient.getInstance().useGetRuntimeConfig();

  const [showSplashModal, setShowSplashModal] = useState(true);
  const onReady = useCallback(() => {
    window.setTimeout(() => {
      setShowSplashModal(false);
    }, 200);
  }, []);

  return (
    <>
      {showSplashScreen ? <SplashScreen show={showSplashModal} /> : null}
      <Suspense fallback={null}>
        <App {...props} runtimeConfig={runtimeConfig.data} onReady={onReady} />
      </Suspense>
    </>
  );
};
